import React, { Fragment, useContext, useEffect } from "react"
import moment from "moment-timezone"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { GATSBY_AWS_SES_EMAIL_TO } from "gatsby-env-variables"

import Layout from "layout/Layout"
import Container from "layout/Container"
import { AppContext } from "../context/AppContext"

export default ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      complete: file(
        relativePath: { eq: "pages/schedule__completed-colored.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    if (!state?.selfRequest?.requestComplete) {
      navigate("/")
    }
  }, [])

  const complete = data.complete.childImageSharp.fixed
  const locationState = location?.state
  const { schedule, meetingLink } = locationState || {}

  const scheduleDate = schedule?.timestamp
    ? moment(schedule?.timestamp).format("dddd, MMMM DD")
    : ""
  const scheduleTime = schedule?.timestamp
    ? `${moment(schedule?.timestamp).format("h:mm A")} - ${moment(
        schedule?.timestamp
      )
        .add(60, "minutes")
        .format("h:mm A")}`
    : ""

  const handleFinish = async () => {
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    await dispatch({ type: "RESET_DETAILS" })
    navigate("/")
  }

  return (
    <Layout seoTitle="Teleconsult Request Sent">
      <Container isCentered>
        <center>
          <h1 className="title is-size-3 mt-5 mb-2">Thank you!</h1>
          <Container isCentered mobile={8}>
            <Img fixed={complete} alt="Success!" />
          </Container>
          <Fragment>
            {scheduleDate ? (
              <Fragment>
                <p className="mt-1 is-size-6">
                  You have requested an appointment on
                </p>
                <div className="box is-shadowless has-text-center mx-5 mx-0-mobile">
                  <h5 className="has-text-primary my-0">{scheduleDate}</h5>
                  <p className="has-text-primary mt-0">{scheduleTime}</p>
                  <p className="has-text-grey is-size-6">
                    Consultation Link:{" "}
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={meetingLink}
                    >
                      {meetingLink}
                    </a>
                  </p>
                </div>
              </Fragment>
            ) : null}
          </Fragment>
          <p className="my-1 mx-3 is-size-6 mb-2">
            Our mental health coordinator will contact you through SMS or email.
            If you don’t hear from us within the next working day, or should you
            wish to change some of your answers, please email us at{" "}
            <a
              href={`mailto:${GATSBY_AWS_SES_EMAIL_TO}`}
              className="has-text-weight-bold"
            >
              {GATSBY_AWS_SES_EMAIL_TO}
            </a>
            .
          </p>
          <button
            onClick={handleFinish}
            className="button is-primary is-medium"
          >
            Finish
          </button>
        </center>
      </Container>
    </Layout>
  )
}
